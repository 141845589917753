import cn from "clsx";
import { useRouter } from "next/router";
import {
  FC,
  MouseEventHandler,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Logo, Menu } from "@components/commun";
import {
  FRONT_SESSION_CODE,
  FRONT_SESSION_CODE2,
  URL_PAGE_BILAN,
  URL_PAGE_SUCCESS,
} from "@middleware/constants";
import { Link } from "@components/ui";
import { StepsProgrammes } from "@components/catalog/steps/StepsProgrammes";
import { getFromStorage } from "@middleware/helpers/global/sessions";
import { EFrontType, EFrontType2 } from "@middleware/types";
import { PictoPhone } from "../PictoPhone/PictoPhone";
import { PictoAccount } from "../PictoAccount/PictoAccount";
import { PictoCart } from "../PictoCart/PictoCart";
import styles from "./NavBar.module.scss";
import ModalCallMe from "../ModalCallMe/ModalCallMe";

export interface Props {
  showModal: MouseEventHandler;
  logout: (event: SyntheticEvent) => Promise<void>;
  isUserInProgramFunnel: boolean;
  isFullScreenPage?: boolean;
}

export const NavBar: FC<Props> = ({
  showModal,
  logout,
  isUserInProgramFunnel,
  isFullScreenPage = false,
}) => {
  const { pathname } = useRouter();
  const showHeader = !isUserInProgramFunnel && pathname !== URL_PAGE_SUCCESS;
  const forcingFront = getFromStorage(FRONT_SESSION_CODE);
  const forcingFront2 = getFromStorage(FRONT_SESSION_CODE2);
  const [isClickable, setIsClickable] = useState(true);
  const [showCallme, setShowCallme] = useState(true);
  useEffect(() => {
    const logIsClickable = !(
      forcingFront === EFrontType.STYLE13 && pathname === URL_PAGE_BILAN
    );
    const showCallmeButton = forcingFront2 === EFrontType2.STYLE5;
    setShowCallme(showCallmeButton);
    setIsClickable(logIsClickable);
  }, [forcingFront, pathname, forcingFront2]);

  return (
    <>
      <div
        className={cn(styles.brand, {
          [styles.isFunnel]: isUserInProgramFunnel,
        })}
      >
        {isClickable ? (
          <Link href="/" className="simple">
            <Logo />
          </Link>
        ) : (
          <span className="simple">
            <Logo />
          </span>
        )}
      </div>

      {showHeader && (
        <Menu
          isFullScreenPage={isFullScreenPage}
          device="desktop"
          showModal={showModal}
          logout={logout}
        />
      )}

      {isUserInProgramFunnel && <StepsProgrammes />}

      <div
        className={cn(styles.rightNav, {
          [styles.isFunnel]: isUserInProgramFunnel,
        })}
      >
        <PictoPhone />
        {showCallme && <ModalCallMe />}
        {!isFullScreenPage && (
          <>
            <PictoAccount showModal={showModal} logout={logout} />
            <PictoCart />
          </>
        )}
      </div>
    </>
  );
};
