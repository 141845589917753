import WrapApi from "@middleware/helpers/api/wrapApi.class";
import {
  EOrderType,
  EPaymentMethods,
  EPaymentState,
  EPayZenFormAction,
  EResponseType,
  EStateOrder,
  IAllUpcomingOrder,
  IChannelResponse,
  IFormToken,
  IOrder,
  IOrderAddress,
  IPayboxForm,
  IPaymentMethod,
  IPromo,
  IRelayPoints,
  ISddFormData,
  IShipmentDetail,
  IShippingMethodsResponse,
  OrderItemSelection,
  TBulkData,
  TResponseError,
} from "@middleware/types";
import { AxiosError, AxiosResponse, HttpStatusCode } from "axios";
import {
  clearToStorage,
  getFromStorage,
} from "@middleware/helpers/global/sessions";
import { START_DATE_SESSION_CODE } from "@middleware/constants";
export default class Cart extends WrapApi {
  channelsCache: IChannelResponse | undefined;

  pickupCart = async (): Promise<IOrder> => {
    const cartResponse = await this.postResource("shop/orders", {});
    if (cartResponse.status !== 201) clearToStorage();

    return cartResponse.data as IOrder;
  };

  getCartByToken = async (
    tokenValue: string,
    canPickup = true,
  ): Promise<IOrder> => {
    const cartResponse = await this.getResource("shop/orders/{tokenValue}", {
      tokenValue,
    });
    if (canPickup && cartResponse.status !== 200)
      return await this.pickupCart();

    return cartResponse.data as IOrder;
  };
  getCartByTokenOfOptimum = async (tokenValue: string): Promise<IOrder> => {
    const cartResponse = await this.getResource(
      "shop/orders/{tokenValue}/optimum",
      {
        tokenValue,
      },
    );

    return cartResponse.data as IOrder;
  };

  applyCouponCode = async (
    cartState: EStateOrder,
    tokenValue: string,
    couponCode: string | null,
  ): Promise<AxiosResponse> => {
    if (cartState === EStateOrder.ORDER_STATE_NEW) {
      return await this.putResource(
        "shop/upcoming/orders/{tokenValue}",
        { couponCode },
        { tokenValue },
      );
    }

    return await this.putResource(
      "shop/orders/{tokenValue}",
      { couponCode },
      { tokenValue },
    );
  };

  getPromotionCouponCode = async (
    subscriptionTypeCode: string,
  ): Promise<IPromo> => {
    const promotionCouponCode = await this.getResource(
      "shop/promotions/subscription-type-promotion-code/{subscriptionTypeCode}",
      { subscriptionTypeCode },
    );

    return promotionCouponCode.data as IPromo;
  };

  pushOrderItems = async (
    paymentState: EPaymentState,
    orderState: EStateOrder,
    tokenValue: string,
    orderItems: OrderItemSelection[],
    promotionCouponCode: string | undefined | null = "",
    type: EOrderType,
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    const data: TBulkData = { orderItems, promotionCouponCode, type };

    if (paymentState === EPaymentState.PAID) {
      return await this.postResource(
        "shop/upcoming/orders/{tokenValue}/bulk/paid",
        data,
        { tokenValue },
      );
    }

    if (orderState === EStateOrder.ORDER_STATE_NEW) {
      return await this.postResource(
        "shop/upcoming/orders/{tokenValue}/bulk",
        data,
        { tokenValue },
      );
    }

    return await this.postResource("shop/orders/{tokenValue}/bulk", data, {
      tokenValue,
    });
  };

  getShippingMethods = async (
    tokenValue: string,
    isSepaOrder = false,
  ): Promise<IShippingMethodsResponse | false> => {
    const startDate = getFromStorage(START_DATE_SESSION_CODE) as
      | string
      | undefined;
    const forcingDate = startDate !== undefined ? { startDate } : undefined;

    const route = isSepaOrder
      ? "shop/orders/{tokenValue}/sepa/shipping-methods"
      : "shop/orders/{tokenValue}/shipping-methods";
    const shippingMethods = await this.getResource(
      route,
      {
        tokenValue,
      },
      forcingDate,
    );

    if (shippingMethods.status !== 200) return false;

    const results = shippingMethods.data as IShippingMethodsResponse;
    if (Object.keys(results.schedule).length === 0) return false;

    return results;
  };

  pushOrderAddress = async (
    tokenValue: string,
    orderAddress: IOrderAddress,
    couponCode: string | null,
  ): Promise<
    | (AxiosResponse<IOrder> & AxiosResponse<TResponseError>)
    | AxiosResponse<AxiosError>
  > => {
    return await this.putResource(
      "shop/orders/{tokenValue}",
      { ...orderAddress, couponCode },
      { tokenValue },
    );
  };

  saveShipmentMethod = async (
    tokenValue: string,
    shipmentId: number,
    chosenDeliveryDate: string,
    shippingMethod: string,
    shipmentDetails:
      | IShipmentDetail
      | { relaisDetails: IRelayPoints | undefined },
    isUpcomingOrder: boolean,
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    const routeApi = isUpcomingOrder
      ? "shop/upcoming/orders/{tokenValue}/shipments/{shipmentId}"
      : "shop/orders/{tokenValue}/shipments/{shipmentId}";

    return await this.patchResource(
      routeApi,
      { chosenDeliveryDate, shipmentDetails, shippingMethod },
      { tokenValue, shipmentId },
    );
  };

  getChannel = async (codeChannel: string): Promise<IChannelResponse> => {
    if (this.channelsCache) {
      return this.channelsCache;
    }

    const result = await this.getResource("shop/channels/{code}", {
      code: codeChannel,
    });
    this.channelsCache = result.data as IChannelResponse;

    return result.data as IChannelResponse;
  };

  getCustomerOrders = async (): Promise<IOrder[]> => {
    const result = await this.getResource("shop/orders");

    if (result.status !== HttpStatusCode.Ok) return [];

    return result.data as IOrder[];
  };

  getSubscriptionOrders = async (
    customerId: number,
  ): Promise<IAllUpcomingOrder | undefined> => {
    const result = await this.getResource(
      "shop/customers/{id}/all-upcoming-orders",
      { id: customerId },
    );

    return result.status === HttpStatusCode.Ok
      ? (result.data as IAllUpcomingOrder)
      : undefined;
  };

  getPaymentMethods = async (): Promise<IPaymentMethod[]> => {
    const paymentMethods = await this.getResource("shop/payment-methods");

    return paymentMethods.data as IPaymentMethod[];
  };

  getCreditCardFormToken = async (
    cartToken: string,
    typeOrder: EOrderType,
  ): Promise<IFormToken> => {
    const result = await this.postResource(
      `shop/orders/${cartToken}/payzen/prepare-form`,
      {
        payment_method: EPaymentMethods.CREDIT_CARD,
        form_action: EPayZenFormAction.PAYMENT,
        type: typeOrder,
      },
    );

    return result.data as IFormToken;
  };

  createNewPaymentToken = async (): Promise<IFormToken> => {
    const result = await this.postResource(
      `shop/payment/payzen/form/create-token`,
    );

    return result.data as IFormToken;
  };

  getCreditCardUpdateFormToken = async (
    orderToken: string,
  ): Promise<IFormToken> => {
    const result = await this.postResource(
      `shop/orders/${orderToken}/payzen/prepare-form`,
      {
        payment_method: EPaymentMethods.CREDIT_CARD,
        form_action: EPayZenFormAction.UPDATE,
      },
    );

    return result.data as IFormToken;
  };

  getSddFormData = async (
    cartToken: string,
    formAction: EPayZenFormAction,
    typeOrder: EOrderType,
  ): Promise<ISddFormData> => {
    const result = await this.postResource(
      `shop/orders/${cartToken}/payzen/prepare-form`,
      {
        payment_method: EPaymentMethods.SEPA,
        form_action: formAction,
        type: typeOrder,
      },
    );

    return result.data as ISddFormData;
  };

  getPayboxFormData = async (
    cartToken: string,
    formAction: EPayZenFormAction,
    typeOrder: EOrderType,
  ): Promise<IPayboxForm> => {
    const result = await this.postResource(
      `shop/payment/paybox/${cartToken}/prepare-form`,
      {
        payment_method: EPaymentMethods.PAYBOX,
        form_action: formAction,
        type: typeOrder,
      },
    );

    return result.data as IPayboxForm;
  };

  completeOrder = async (
    tokenValue: string,
    notes = "",
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    return await this.patchResource(
      "shop/orders/{tokenValue}/complete",
      {
        notes,
      },
      {
        tokenValue,
      },
    );
  };
  payRegularizationAmount = async (
    tokenValue: string,
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    return await this.patchResource(
      "shop/orders/{tokenValue}/pay",
      {},
      {
        tokenValue,
      },
    );
  };

  skipOrder = async (
    tokenValue: string,
    skip: boolean,
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    return await this.patchResource(
      "shop/orders/{tokenValue}/skip",
      {
        isSkipped: skip,
      },
      {
        tokenValue,
      },
    );
  };

  getOrderInvoice = async (tokenValue: string): Promise<string> => {
    const invoiceResponse = await this.getResource(
      "shop/orders/{tokenValue}/invoice",
      {
        tokenValue,
      },
      null,
      EResponseType.BLOB,
    );

    return invoiceResponse.data as string;
  };

  pushCompleteOrderAddress = async (
    tokenValue: string,
    orderAddress: IOrderAddress,
    couponCode: string | null,
  ): Promise<AxiosResponse<IOrder> | AxiosResponse<AxiosError>> => {
    return await this.putResource(
      "shop/upcoming/orders/{tokenValue}",
      { ...orderAddress, couponCode },
      { tokenValue },
    );
  };
}
