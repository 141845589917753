import {
  BUNDEL_COMPLEMENT_CODE,
  BUNDEL_MEALS_CHOCO,
  BUNDEL_MEALS_CODE,
  BUNDEL_MEALS_DESSERT,
  BUNDEL_MEALS_THE,
  BUNDLE_ATTRIBUTE_DAYS,
  BUNDLE_BREAKFAST_CODE,
  BUNDLE_CHECKOUT_ENABLED,
  BUNDLE_CODE_DEFAULT,
  BUNDLE_CODE_DEFAULT_COUPON_CODE,
  BUNDLE_CODE_MEALS,
  BUNDLE_ECO_TAXON_CODE,
  BUNDLE_EXTRA_QUANTITY,
  BUNDLE_INITIAL_CONFIGS,
  BUNDLE_OPTIMUM_TAXON_CODE,
  BUNDLE_ORIGINAL_FRAIS_TAXON_CODE,
  BUNDLE_ORIGINAL_TAXON_CODE,
  BUNDLE_PROGRAM_DAYS,
  BUNDLE_SNACK_CODE,
  BUNDLE_STAB_TAXON_CODE,
  BUNDLE_TAXON_CONFIGURATION,
  CODE_EXTRA_MAIN_TAXON,
  CONFIG_SUBSCRIPTION_TUNNEL_TYPE,
  DEFAULT_PROGRAM_DAYS,
  ECO_SUMMARY_BG,
  MEAL_CODE_FOOD_PREFERENCE,
  MEAL_CODE_INGREDIENT_DESCRIPTION,
  MEAL_CODE_NEW,
  MEAL_CODE_NUTRISCORE,
  MEAL_CODE_NUTRITIONAL_VALUE,
  MEAL_CODE_PREPARATION_ADVICE,
  MEAL_CODE_STORAGE_INSTRUCTIONS,
  OFFERED_BREAKFASTS_SNACK,
  OFFERED_BREAKFASTS_SNACK_FRAIS,
  OPTIMUM_SUMMARY_BG,
  ORIGINAL_SUMMARY_BG,
  STAB_SUMMARY_BG,
  VARIANT_CODE_MAX_WEIGHT,
  VARIANT_CODE_MIN_WEIGHT,
} from "@middleware/constants";
import { getLocale } from "@config/locales";
import {
  AssigningType,
  BundleCodePromotionMap,
  BundlePromotion,
  BundlePromotionDetails,
  CHANNEL_CODES,
  DiscountType,
  DiscountTypeValue,
  ECanStabError,
  EProductVariantType,
  EProgramType,
  ERuleType,
  ERuleTypeValue,
  ESubscriptionState,
  IBundleAttribute,
  IConfiguration,
  IConfigurationResponse,
  ICustomer,
  IMenuResponse,
  IObjectif,
  IOrder,
  IProduct,
  IProductResponse,
  IProductTaxon,
  IProgram,
  IPromotionCouponActionResponse,
  IPromotionCouponAssigningActionResponse,
  IPromotionCouponDiscountActionResponse,
  IPromotionCouponRuleResponse,
  IPromotionCouponsResponse,
  ISegmentConfiguration,
  IVariant,
  TConfigurationTunnelType,
  TNoveltyOption,
  TProgramType,
  TSubscriptionState,
} from "@middleware/types";
import { AxiosError, AxiosResponse } from "axios";
import optimumIcon from "@static/images/icons/green-shape.png";
import originalIcon from "@static/images/icons/shape.png";
import {
  getFormattedPrice,
  getFormattePromotionBundlePrice,
  getMatchingPromotion,
  limitText,
  removeDuplicates,
} from "./utils";
import { getIsProspect } from "./customer";
import { isCateringOrder } from "./cart";

export const getProductDescription = (product: IProduct, short = true) => {
  return short ? limitText(product.shortDescription, 130) : product.description;
};

export const serializeProduct = (
  bundles: IProductResponse[],
  typeVariant: EProductVariantType,
  isOptimum?: boolean,
): IProduct[] => {
  if (!Array.isArray(bundles)) return [];

  const mapping = {
    [BUNDLE_PROGRAM_DAYS]: "programDays",
    [BUNDLE_CHECKOUT_ENABLED]: "checkoutEnabled",
    [BUNDLE_CODE_DEFAULT]: "isDefault",
    [BUNDLE_CODE_MEALS]: "mealsNumber",
    [BUNDLE_CODE_DEFAULT_COUPON_CODE]: "defaultCouponCode",
    [MEAL_CODE_NUTRISCORE]: "nutriscore",
    [MEAL_CODE_FOOD_PREFERENCE]: "foodPreference",
    [MEAL_CODE_INGREDIENT_DESCRIPTION]: "ingredients",
    [MEAL_CODE_NUTRITIONAL_VALUE]: "nutritional",
    [MEAL_CODE_PREPARATION_ADVICE]: "preparation",
    [MEAL_CODE_STORAGE_INSTRUCTIONS]: "storageInstructions",
    [BUNDLE_TAXON_CONFIGURATION]: "extraTaxonConfiguration",
    [BUNDLE_EXTRA_QUANTITY]: "extraTaxonQuantity",
    [MEAL_CODE_NEW]: "novelty",
  };

  const newBundlesFormat = bundles
    .filter(
      (item) =>
        item.variants.filter(
          (variant) =>
            variant.enabled &&
            (variant.type === typeVariant ||
              variant.type === EProductVariantType.ALL ||
              typeVariant === EProductVariantType.ALL),
        ).length > 0,
    )
    .map((bundle) => {
      const attributes = bundle.attributes?.reduce((accumulator, attribute) => {
        if (
          attribute.localeCode !== null &&
          attribute.localeCode !== getLocale()
        )
          return accumulator;

        if (attribute.attributeCode in mapping) {
          return {
            ...accumulator,
            [mapping[attribute.attributeCode as keyof typeof mapping]]:
              attribute.value,
          };
        }

        return accumulator;
      }, {});

      const packaging = bundle.packaging ?? 1;
      const variants = bundle.variants;
      const desktopPosition = bundle.desktopPosition ?? 1;
      const mobilePosition = bundle.mobilePosition ?? 1;
      const enableVariant = getEnabledVariant(
        bundle.variants,
        typeVariant,
        isOptimum,
      );
      const enabled = bundle.enabled && enableVariant?.enabled === true;
      const showInBundles = bundle.bundles
        ? bundle.bundles.map((code) => code)
        : [];

      return {
        id: bundle.id,
        code: bundle.code,
        codeVariant: enableVariant?.code ?? "",
        name: bundle.name,
        shortDescription: bundle.shortDescription,
        description: bundle.description,
        ingredients: "",
        nutritional: "",
        preparation: "",
        price: enableVariant ? enableVariant.price / packaging : 0,
        originalPrice: enableVariant
          ? enableVariant.originalPrice / packaging
          : 0,
        priority: bundle.priority ?? 0,
        foodPreference: [],
        isDefault: false,
        programDays: DEFAULT_PROGRAM_DAYS,
        checkoutEnabled: false,
        mealsNumber: 0,
        defaultCouponCode: "",
        nutriscore: [],
        storageInstructions: [],
        extraTaxonConfiguration: { values: [] },
        extraTaxonQuantity: { values: [] },
        image: bundle.images.length > 0 ? bundle.images[0].path : "",
        weight: enableVariant?.weight ?? 0,
        volume: enableVariant?.volume ?? 0,
        packaging,
        desktopPosition,
        mobilePosition,
        recommendedQuantity: 0,
        enabled,
        showInBundles,
        ...attributes,
        isNew:
          (
            attributes as {
              novelty?: TNoveltyOption;
            }
          ).novelty?.values.some(
            (value) => value.new && value.channel === CHANNEL_CODES.DIETBON,
          ) ?? false,
        variants,
        productTaxons: bundle.productTaxons,
        taxons: bundle.productTaxons.map(
          (productTaxon) => productTaxon.taxon.code ?? "",
        ),
      };
    });

  return newBundlesFormat;
};

export const getEnabledVariant = (
  variants: IVariant[],
  type: EProductVariantType,
  isOptimum?: boolean,
) => {
  const enableVariant = variants.find(
    (variant) =>
      variant.enabled &&
      (variant.type === type ||
        variant.type === EProductVariantType.ALL ||
        type === EProductVariantType.ALL),
  );

  const optimumVariant = variants.find(
    (variant) => variant.type !== EProductVariantType.ALC,
  );

  return enableVariant
    ? enableVariant
    : isOptimum === true
    ? optimumVariant
    : undefined;
};

export const serializeConfigurations = (
  configurations: IConfigurationResponse[],
  type: TConfigurationTunnelType,
): IConfiguration[] => {
  return configurations
    .map(
      (config): IConfiguration => ({
        label: config.name,
        label2: config.name,
        id: config.taxon.code,
        required: false,
        priority: config.priority,
        visited: false,
        tunnelType: config.tunnelType,
      }),
    )
    .filter((config) => config.tunnelType === type);
};
export const sortBundles = (bundles: IProduct[], ordre = "ASC"): IProduct[] => {
  if (!Array.isArray(bundles)) return [];

  return ordre === "ASC"
    ? bundles.sort((a, b) => (a.mealsNumber > b.mealsNumber ? 1 : -1))
    : bundles.sort((a, b) => (a.mealsNumber < b.mealsNumber ? 1 : -1));
};
export const sortConfigurations = (
  configurations: IConfiguration[],
  ordre = "ASC",
): IConfiguration[] => {
  return ordre === "ASC"
    ? configurations.sort((a, b) => (a.priority > b.priority ? 1 : -1))
    : configurations.sort((a, b) => (a.priority < b.priority ? 1 : -1));
};

export const sortMeals = (meals: IProduct[]): IProduct[] => {
  return meals.sort((a, b) => (a.priority > b.priority ? 1 : -1));
};

export const getProductsFromResponse = <T>(
  productResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  type: EProductVariantType,
  order = "ASC",
): IProduct[] => {
  return sortBundles(
    serializeProduct(productResponse.data as IProductResponse[], type),
    order,
  );
};

export const getBundleFromResponse = <T>(
  bundleResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
): IProduct => {
  return serializeProduct(
    [bundleResponse.data] as IProductResponse[],
    EProductVariantType.ALL,
  )[0];
};

export const getBundlePromotionsFromResponse = <T>(
  bundlePromotionsResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
): BundleCodePromotionMap => {
  const promotionsResponse = bundlePromotionsResponse.data as BundlePromotion[];

  return promotionsResponse.reduce<BundleCodePromotionMap>((acc, promotion) => {
    if (promotion.discountAmount !== 0) {
      const { bundleCode, ...rest } = promotion;
      acc[bundleCode] = rest;
    }

    return acc;
  }, {});
};

export const getConfigurationFromResponse = <T>(
  configurationResponse: AxiosResponse<T> | AxiosResponse<AxiosError>,
  type: TConfigurationTunnelType = CONFIG_SUBSCRIPTION_TUNNEL_TYPE,
  order = "ASC",
): IConfiguration[] => {
  return [
    ...BUNDLE_INITIAL_CONFIGS,
    ...sortConfigurations(
      serializeConfigurations(
        configurationResponse.data as IConfigurationResponse[],
        type,
      ),
      order,
    ),
  ];
};

export const getProductsOfMenu = (
  productResponse: AxiosResponse | AxiosResponse<AxiosError>,
  type: EProductVariantType,
  isOptimum?: boolean,
): IProduct[] => {
  const menuItemsResponse = productResponse.data as IMenuResponse;
  const productsResponse: IProductResponse[] = menuItemsResponse.items.map(
    (item) => {
      return {
        ...item.product,
        isNew: item.new,
        priority: item.priority,
      };
    },
  );

  return sortMeals(serializeProduct(productsResponse, type, isOptimum));
};
export const isPromotionFirstOrder = (
  promotionRules: IPromotionCouponRuleResponse[],
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_FIRST_ORDER,
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[ERuleTypeValue.NTH] === 1;

  return false;
};
export const getPromotionSegments = (
  promotionRules: IPromotionCouponRuleResponse[],
) => {
  const promotionRule = promotionRules.find(
    (promotion) => promotion.type === ERuleType.RULE_SEGMENT,
  );
  if (promotionRule !== undefined)
    return promotionRule.configuration[
      ERuleTypeValue.SEGMENT
    ] as ISegmentConfiguration;

  return undefined;
};
export const getPromotionSubscriptionTypeCode = (
  promotionActions: IPromotionCouponActionResponse[],
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type),
    )
    .find((action) => action.type === AssigningType.SUBSCRIPTION_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["subscription_type_code"];
};

export const getPromotionGroupCode = (
  promotionActions: IPromotionCouponActionResponse[],
): string | null => {
  const promotionGroupAction = promotionActions
    .filter((action): action is IPromotionCouponAssigningActionResponse =>
      Object.values(AssigningType).some((value) => value === action.type),
    )
    .find((action) => action.type === AssigningType.GROUP_ASSIGNER);

  if (promotionGroupAction === undefined) return null;

  return promotionGroupAction.configuration["group"];
};

export const getPromotionDiscountAmount = (
  discountType: DiscountType,
  promotionAction: IPromotionCouponDiscountActionResponse,
): number => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.DIETBON in promotionConfiguration) {
    const channelConfiguration = promotionConfiguration[CHANNEL_CODES.DIETBON];

    switch (discountType) {
      case DiscountType.ORDER_PERCENTAGE:
      case DiscountType.UNIT_PERCENTAGE:
        return channelConfiguration[DiscountTypeValue.PERCENTAGE];
      case DiscountType.VARIANT_FIXED:
      case DiscountType.ORDER_FIXED:
      case DiscountType.UNIT_FIXED:
        return channelConfiguration[DiscountTypeValue.AMOUNT];
      default:
        return 0;
    }
  }

  switch (discountType) {
    case DiscountType.ORDER_PERCENTAGE:
      return promotionConfiguration[DiscountTypeValue.PERCENTAGE];
    default:
      return 0;
  }
};

export const getPromotionVariants = (
  promotionAction: IPromotionCouponDiscountActionResponse,
): string[] => {
  const promotionConfiguration = promotionAction.configuration;

  if (CHANNEL_CODES.DIETBON in promotionConfiguration) {
    const channelConfiguration = promotionConfiguration[CHANNEL_CODES.DIETBON];

    return channelConfiguration.variants_filter?.variants ?? [];
  }

  return [];
};

export const getPromotionCouponFromResponse = (
  promotionCouponsResponse: IPromotionCouponsResponse,
  promotionCoupon: string,
): BundlePromotionDetails[] | null => {
  const promotionDiscountActions = promotionCouponsResponse.actions
    .filter((action): action is IPromotionCouponDiscountActionResponse =>
      Object.values(DiscountType).some((value) => value === action.type),
    )
    .filter((action) => Object.values(DiscountType).includes(action.type));

  if (promotionDiscountActions.length === 0) return null;

  const groupCode = getPromotionGroupCode(promotionCouponsResponse.actions);
  const subscriptionTypeCode = getPromotionSubscriptionTypeCode(
    promotionCouponsResponse.actions,
  );
  const firstOrder = isPromotionFirstOrder(promotionCouponsResponse.rules);
  const segments = getPromotionSegments(promotionCouponsResponse.rules);
  const promotionCoupons: BundlePromotionDetails[] = [];

  promotionDiscountActions.map((promotionDiscountAction) => {
    const discountType = promotionDiscountAction.type;
    const discountAmount = getPromotionDiscountAmount(
      discountType,
      promotionDiscountAction,
    );
    promotionCoupons.push({
      promotionCoupon,
      discountType,
      discountAmount,
      firstOrder,
      groupCode,
      subscriptionTypeCode,
      segments,
      variants: getPromotionVariants(promotionDiscountAction),
    });

    return promotionDiscountAction;
  });

  return promotionCoupons.length > 0 ? promotionCoupons : null;
};

export const getBundleByCode = (
  bundles: IProduct[],
  bundleCode: string,
): IProduct | undefined => {
  return bundles.find((singleBundle) => singleBundle.code === bundleCode);
};

export const getObjectifMin = (variant: IVariant) => {
  return (
    variant.optionValues.find(
      (option) => option.optionCode === VARIANT_CODE_MIN_WEIGHT,
    )?.value ?? 0
  );
};

export const getObjectifMax = (variant: IVariant) => {
  return (
    variant.optionValues.find(
      (option) => option.optionCode === VARIANT_CODE_MAX_WEIGHT,
    )?.value ?? 0
  );
};

export const getObjectifOfVariant = (variant: IVariant) => {
  const minWeight = getObjectifMin(variant);
  const maxWeight = getObjectifMax(variant);

  return `${minWeight}-${maxWeight}`;
};

const hasTaxon = (taxons: IProductTaxon[] | undefined, taxonCode: string) => {
  if (taxons === undefined) return false;

  return taxons.some((item) => item.taxon.code === taxonCode);
};

export const getBundleProgramType = (taxons: IProductTaxon[] | undefined) => {
  if (taxons === undefined) return EProgramType.NONE;

  if (hasTaxon(taxons, BUNDLE_ORIGINAL_TAXON_CODE))
    return EProgramType.ORIGINAL;
  if (hasTaxon(taxons, BUNDLE_OPTIMUM_TAXON_CODE)) return EProgramType.OPTIMUM;
  if (hasTaxon(taxons, BUNDLE_ECO_TAXON_CODE)) return EProgramType.ECO;
  if (hasTaxon(taxons, BUNDLE_STAB_TAXON_CODE)) return EProgramType.STAB;
  if (hasTaxon(taxons, BUNDLE_ORIGINAL_FRAIS_TAXON_CODE))
    return EProgramType.ORIGINAL_FRESH;

  return EProgramType.NONE;
};

export const getProgramDays = (bundle: IProduct) => {
  return bundle.programDays;
};

export const hasBreakfasts = (variant: IVariant) => {
  return variant.optionValues.some(
    (option) => option.code === BUNDLE_BREAKFAST_CODE,
  );
};

export const hasSnacks = (variant: IVariant) => {
  return variant.optionValues.some(
    (option) => option.code === BUNDLE_SNACK_CODE,
  );
};

export const getAllPrograms = (bundles: IProduct[]) => {
  const programs: IProgram[] = [];
  bundles.map((bundle) => {
    bundle.variants?.map((variant) => {
      programs.push({
        code: variant.code,
        objectif: getObjectifOfVariant(variant),
        productTaxon: getBundleProgramType(bundle.productTaxons),
        price: variant.price,
        programDays: getProgramDays(bundle),
        hasSnacks: hasSnacks(variant),
        hasBreakfasts: hasBreakfasts(variant),
        breakfastsAndSnacks: hasBreakfasts(variant) && hasSnacks(variant),
        extraTaxonConfiguration: bundle.extraTaxonConfiguration,
        extraTaxonQuantity: bundle.extraTaxonQuantity,
        mealsNumber: bundle.mealsNumber,
        engagement: variant.subscriptionType?.engagementPeriodNumber ?? 0,
        defaultCouponCode: bundle.defaultCouponCode,
      });

      return variant;
    });

    return bundle;
  });

  return programs.filter((program) => {
    return (
      program.productTaxon === EProgramType.ORIGINAL ||
      program.productTaxon === EProgramType.ECO ||
      program.productTaxon === EProgramType.OPTIMUM ||
      program.productTaxon === EProgramType.ORIGINAL_FRESH
    );
  });
};

export const getStabPrograms = (bundles: IProduct[]) => {
  const programs: IProgram[] = [];
  bundles.map((bundle) => {
    bundle.variants?.map((variant) => {
      programs.push({
        code: variant.code,
        objectif: getObjectifOfVariant(variant),
        productTaxon: getBundleProgramType(bundle.productTaxons),
        price: variant.price,
        programDays: getProgramDays(bundle),
        hasSnacks: hasSnacks(variant),
        hasBreakfasts: hasBreakfasts(variant),
        breakfastsAndSnacks: hasBreakfasts(variant) && hasSnacks(variant),
        extraTaxonConfiguration: bundle.extraTaxonConfiguration,
        extraTaxonQuantity: bundle.extraTaxonQuantity,
        mealsNumber: bundle.mealsNumber,
        engagement: 0,
        defaultCouponCode: bundle.defaultCouponCode,
      });

      return variant;
    });

    return bundle;
  });

  return programs.filter(
    (program) => program.productTaxon === EProgramType.STAB,
  );
};

export const defaultSelection = {
  numberOfDays: 6,
  breakfastsAndSnacks: true,
};

export const getObjectifLabel = (objectif: string) => {
  const refPosition = objectif.indexOf(">");

  return refPosition > 0
    ? objectif.substring(refPosition + 1) + " kg et+"
    : objectif + " kg";
};

export const hasMultiplePrograms = (programs: IProgram[]) => {
  const hasOriginal = programs.some(
    (program) => program.productTaxon === EProgramType.ORIGINAL,
  );
  const hasOriginalFresh = programs.some(
    (program) => program.productTaxon === EProgramType.ORIGINAL_FRESH,
  );
  const hasOptimum = programs.some(
    (program) => program.productTaxon === EProgramType.OPTIMUM,
  );
  const hasEco = programs.some(
    (program) => program.productTaxon === EProgramType.ECO,
  );

  return (hasOriginal || hasOriginalFresh) && (hasOptimum || hasEco);
};
export const hasOptimumPrograms = (programs: IProgram[]) => {
  return programs.some(
    (program) => program.productTaxon === EProgramType.OPTIMUM,
  );
};
export const hasOriginalPrograms = (programs: IProgram[]) => {
  return programs.some(
    (program) => program.productTaxon === EProgramType.ORIGINAL,
  );
};
export const hasEcoPrograms = (programs: IProgram[]) => {
  return programs.some((program) => program.productTaxon === EProgramType.ECO);
};

export const getObjectifPriority = (objectif: string) => {
  return parseInt(objectif.replace("-", "").replace(">", ""));
};

export const getObjectifs = (programs: IProgram[]) => {
  const programsObjectifs = programs.map((program) => program.objectif);
  const objectifs = removeDuplicates(programsObjectifs)
    .map((objectif, index) => {
      return {
        code: objectif,
        label: getObjectifLabel(objectif),
        value: index,
      };
    })
    .sort((a, b) =>
      getObjectifPriority(a.code) > getObjectifPriority(b.code) ? 1 : -1,
    );

  return objectifs;
};

export const handleConfigurationsVisibility = (
  configurations: IConfiguration[] | null,
  bundle: IProgram | undefined,
): IConfiguration[] | null => {
  if (configurations === null || bundle === undefined) return configurations;

  return configurations.filter((config) => {
    if (config.id === BUNDEL_MEALS_CODE) return true;

    if (
      config.id === BUNDLE_BREAKFAST_CODE ||
      config.id === BUNDLE_SNACK_CODE
    ) {
      if (bundle.hasBreakfasts && config.id === BUNDLE_BREAKFAST_CODE)
        return true;

      if (bundle.hasSnacks && config.id === BUNDLE_SNACK_CODE) return true;

      return false;
    }

    const isExist = bundle.extraTaxonQuantity?.values.some(
      (taxonConfig) => taxonConfig.extra === config.id,
    );

    return isExist === true;
  });
};

export const getProgramMealsCount = (program: IProgram) => {
  return [program.mealsNumber];
};

export const getProgramRequiredConfigCount = (
  program: IProgram,
  configCode: string,
) => {
  const ConfigItem = program.extraTaxonConfiguration?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );
  if (ConfigItem) return [parseInt(ConfigItem.quantity)];

  const configQuantity = program.extraTaxonQuantity?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );

  if (configQuantity)
    return configQuantity.quantities
      .split(",")
      .filter((val) => val !== "0")
      .map((item) => parseInt(item));

  return [0];
};

export const getProgramConfigCount = (
  program: IProgram,
  configCode: string,
) => {
  const configItem = program.extraTaxonQuantity?.values.find(
    (taxonConfig) => taxonConfig.extra === configCode,
  );

  return configItem
    ? configItem.quantities.split(",").map((item) => parseInt(item))
    : [0];
};

export const getConfigurationsMealsNumber = (
  program: IProgram,
  codeConfig: string,
) => {
  switch (codeConfig) {
    case BUNDEL_MEALS_CODE:
      return getProgramMealsCount(program);
    case BUNDLE_BREAKFAST_CODE:
      return getProgramRequiredConfigCount(program, BUNDLE_BREAKFAST_CODE);
    case BUNDLE_SNACK_CODE:
      return getProgramRequiredConfigCount(program, BUNDLE_SNACK_CODE);
    case BUNDEL_MEALS_DESSERT:
      return getProgramConfigCount(program, BUNDEL_MEALS_DESSERT);
    case BUNDEL_MEALS_CHOCO:
      return getProgramConfigCount(program, BUNDEL_MEALS_CHOCO);
    case BUNDEL_MEALS_THE:
      return getProgramConfigCount(program, BUNDEL_MEALS_THE);
    case BUNDEL_COMPLEMENT_CODE:
      return getProgramConfigCount(program, BUNDEL_COMPLEMENT_CODE);
    default:
      return [0];
  }
};
export const getConfigurationsMeals = (
  program: IProgram,
  configurations: IConfiguration[],
) => {
  const config = configurations.map((configuration) => {
    const codeConfig = configuration.id;
    const mealsNumber = getConfigurationsMealsNumber(program, codeConfig);

    return {
      code: codeConfig,
      mealsNumber,
    };
  });

  return config;
};

export const getProgramType = (
  customer: ICustomer,
): EProgramType | undefined => {
  return getBundleProgramType(customer.currentSubscription?.bundle.taxons);
};

export const getProgramStatusLabel = (
  status: TSubscriptionState | undefined,
) => {
  switch (status) {
    case ESubscriptionState.ON_GOING:
      return "pages.onGoingStatus";
    case ESubscriptionState.PAUSED:
      return "pages.pausedStatus";
    case ESubscriptionState.DONE:
      return "pages.doneStatus";
    case ESubscriptionState.CANCELED:
      return "pages.canceledStatus";
    case ESubscriptionState.CLOSED:
      return "pages.closedStatus";
    case ESubscriptionState.UNPAID:
      return "pages.unpaidStatus";
    default:
      return "";
  }
};
export const getProgramIcon = (programType: TProgramType | undefined) => {
  if (programType === EProgramType.OPTIMUM) return optimumIcon;
  if (
    programType === EProgramType.ORIGINAL ||
    programType === EProgramType.ORIGINAL_FRESH
  )
    return originalIcon;

  return null;
};
export const getProgramColor = (programType: TProgramType | undefined) => {
  if (programType === EProgramType.OPTIMUM) return OPTIMUM_SUMMARY_BG;
  if (programType === EProgramType.ORIGINAL) return ORIGINAL_SUMMARY_BG;
  if (programType === EProgramType.STAB) return STAB_SUMMARY_BG;
  if (programType === EProgramType.ECO) return ECO_SUMMARY_BG;

  return ORIGINAL_SUMMARY_BG;
};
export const getProgramDaysPerWeek = (
  attributes: IBundleAttribute[],
): number | null => {
  const days =
    attributes
      .find((attribute) => attribute.attributeCode === BUNDLE_ATTRIBUTE_DAYS)
      ?.value.toString() ?? null;

  return days !== null ? parseInt(days) : null;
};

export const programContainsTheMeal = (
  attributes: IBundleAttribute[],
  meal: string,
) => {
  const configs = attributes.find(
    (attribute) => attribute.attributeCode === BUNDLE_TAXON_CONFIGURATION,
  )?.value;

  if (Array.isArray(configs)) {
    return configs.some(
      (config) => typeof config === "object" && config.extra === meal,
    );
  }

  return false;
};

export const programContainsTheExtra = (
  attributes: IBundleAttribute[],
  extraCode: string,
) => {
  const configs = attributes.find(
    (attribute) => attribute.attributeCode === BUNDLE_EXTRA_QUANTITY,
  )?.value;

  if (typeof configs === "object" && Array.isArray(configs.values)) {
    return configs.values.some((config) => config.extra === extraCode);
  }

  return false;
};

export const isBreakfastProduct = (product: IProduct) => {
  if (!product.taxons) return false;

  return (
    product.taxons.filter((taxon) => taxon === BUNDLE_BREAKFAST_CODE).length > 0
  );
};

export const isSnackProduct = (product: IProduct) => {
  if (!product.taxons) return false;

  return (
    product.taxons.filter((taxon) => taxon === BUNDLE_SNACK_CODE).length > 0
  );
};

export const isDessertProduct = (product: IProduct) => {
  if (!product.taxons) return false;

  return (
    product.taxons.filter((taxon) => taxon === BUNDEL_MEALS_DESSERT).length > 0
  );
};

export const isExtrasProduct = (product: IProduct) => {
  if (!product.taxons) return false;

  return (
    product.taxons.filter((taxon) => taxon === CODE_EXTRA_MAIN_TAXON).length > 0
  );
};

export const checkIfBreakfastAndSnackAreIncluded = (bundle: IVariant) => {
  const hasSnack = bundle.optionValues.find(
    (item) => item.optionCode === "taxons" && item.code === BUNDLE_SNACK_CODE,
  );
  const hasBreakfast = bundle.optionValues.find(
    (item) =>
      item.optionCode === "taxons" && item.code === BUNDLE_BREAKFAST_CODE,
  );

  if (!hasSnack && !hasBreakfast) {
    return {
      key: "pages.withoutBreakfastAndSnack",
    };
  } else if (!hasSnack) {
    return {
      key: "pages.withoutSnack",
    };
  } else if (!hasBreakfast) {
    return {
      key: "pages.withoutBreakfast",
    };
  }

  return {
    key: "pages.withBreakfastAndSnack",
  };
};

export const getOfferedBreakfastsSnack = (bundle: IProgram) => {
  const isStabProgram = bundle.productTaxon === EProgramType.STAB;
  const isOriginalProgram = bundle.productTaxon === EProgramType.ORIGINAL;
  const isEcoProgram = bundle.productTaxon === EProgramType.ECO;
  const isCateringOffer = bundle.productTaxon === EProgramType.ORIGINAL_FRESH;
  if (!isStabProgram && !isOriginalProgram && !isEcoProgram && !isCateringOffer)
    return 0;

  if ([3, 5, 7].includes(bundle.programDays))
    return isCateringOffer
      ? OFFERED_BREAKFASTS_SNACK_FRAIS
      : OFFERED_BREAKFASTS_SNACK;

  return 0;
};

export const getProgram = (
  allPrograms: IProgram[],
  objectif: IObjectif,
  numberOfDays: number,
  hasMeals: boolean,
  programType: TProgramType,
) => {
  const filtredPrograms =
    programType === EProgramType.OPTIMUM
      ? allPrograms.filter((program) => program.objectif === objectif.code)
      : allPrograms.filter(
          (program) =>
            program.objectif === objectif.code &&
            program.programDays === numberOfDays &&
            (program.breakfastsAndSnacks === hasMeals ||
              EProgramType.ECO === programType),
        );
  const program = filtredPrograms.find(
    (item) => item.productTaxon === programType,
  );

  return program;
};

export const getDefaultPromotion = (
  selectedBundle: IProgram,
  bundlePromotions?: BundleCodePromotionMap,
) => {
  return bundlePromotions
    ? getMatchingPromotion(selectedBundle, bundlePromotions)
    : undefined;
};

export const getProgramLabel = (type: EProgramType | undefined) => {
  if (type === EProgramType.ECO) return "pages.eco";
  if (type === EProgramType.ORIGINAL) return "pages.original";
  if (type === EProgramType.OPTIMUM) return "pages.optimum";

  return "";
};

export const getMinProgramPrice = (
  objectif: IObjectif | undefined,
  programType: TProgramType,
  allPrograms: IProgram[],
  numberOfDays: number,
  hasMeals: boolean,
  bundlePromotions?: BundleCodePromotionMap,
  customer?: ICustomer,
  dividedPriceByTwo: boolean = false,
): string | undefined => {
  if (!objectif) return;

  const program = getProgram(
    allPrograms,
    objectif,
    numberOfDays,
    hasMeals,
    programType,
  );
  const bundlePromotion = program
    ? getDefaultPromotion(program, bundlePromotions)
    : undefined;

  if (
    bundlePromotion === undefined ||
    !(getIsProspect(customer) || !bundlePromotion.firstOrder)
  )
    return getFormattedPrice(
      dividedPriceByTwo ? (program?.price ?? 0) / 2 : program?.price,
    );

  return program
    ? getFormattePromotionBundlePrice(
        program.price,
        bundlePromotion.discountAmount,
        bundlePromotion.discountType,
      )
    : undefined;
};

export const findLowestPricedProgram = (
  programs: IProgram[],
  filterCondition: (program: IProgram) => boolean,
): IProgram | undefined => {
  const filteredPrograms = programs.filter(filterCondition);
  if (filteredPrograms.length === 0) {
    return undefined;
  }

  return filteredPrograms.reduce(
    (lowest, program) => (program.price < lowest.price ? program : lowest),
    filteredPrograms[0],
  );
};

export const getStabTranslationKey = (customer: ICustomer): string => {
  switch (customer.canStabError) {
    case ECanStabError.CHANNEL_INVALID:
      return "stabilisation.invalidChannel";
    case ECanStabError.CUSTOMER_HAS_NO_SUBSCRIPTION:
      return "stabilisation.noSubscription";
    case ECanStabError.SLIMMING_ONGOING_PROGRAM_ENGAGED:
      return "stabilisation.ongoingProgramEngaged";
    case ECanStabError.SLIMMING_ONGOING_PROGRAM_NON_ENGAGED:
      return "stabilisation.ongoingProgramNoEngaged";
    case ECanStabError.SLIMMING_UNPAID_PROGRAM:
      return "stabilisation.unpaidProgram";
    case ECanStabError.STAB_ONGOING_PROGRAM:
      return "stabilisation.ongoingProgram";
    case ECanStabError.STAB_UNPAID_PROGRAM:
      return "stabilisation.unpaidStabProgram";
    default:
      return "stabilisation.unknown";
  }
};
export const getProgramEngagement = (order: IOrder): number => {
  const isCateringOffer: boolean = isCateringOrder(order);
  const engagementPeriodNumber =
    order.subscriptionType?.engagementPeriodNumber ?? 0;
  const engagement = isCateringOffer
    ? engagementPeriodNumber / 2
    : engagementPeriodNumber;

  return engagement;
};
