import { NextPageContext } from "next";
import { ReactElement } from "react";
import {
  REMOVE_AUTH_DETAILS,
  REMOVE_DETAILS,
  SET_AUTH_DETAILS,
  SET_DETAILS,
} from "@middleware/constants";
import { ValueOf } from "next/dist/shared/lib/constants";
import { IOrder, IVariant, TProgramType } from "../commerce";
export enum EType {
  ERROR = "ERROR",
  WARNING = "WARNING",
  SUCCESS = "SUCCESS",
  NONE = "NONE",
}
export type IType = "ERROR" | "WARNING" | "SUCCESS" | "NONE";
export type TResponseError = {
  detail: string;
  title: string;
  type: string;
  violations?: { [name: string]: string }[];
  errorCode?: string;
};

export interface IMessage {
  text: string | null;
  type: IType | null;
}
export interface StateMessaging {
  message: IMessage;
  setMessage: (message: IMessage) => void;
  cleanMessage: () => void;
}
export interface IGlobalStatus {
  message: IMessage;
}
export interface IAppContext {
  Component: ReactElement;
  ctx: NextPageContext;
}
export interface IRedirectOptions {
  ctx: NextPageContext;
  status: number;
}
export type ActionType =
  | {
      type: typeof SET_DETAILS;
      message: IMessage;
    }
  | {
      type: typeof REMOVE_DETAILS;
    };

export interface IAction {
  type?: ActionType;
  payload?: IGlobalStatus;
}

export type ILocal = "fr_FR" | "es";

export interface ILoginIn {
  email: string;
  password: string;
  tokenValue?: string;
}
export interface IChangePasswordData {
  newPassword: string;
  confirmNewPassword: string;
  currentPassword?: string;
}
export interface IAuthResponse {
  token: string;
}
export interface AuthInfo {
  isAuthenticated: boolean | undefined;
  user: IUser | null;
  customer: ICustomer | null;
}
export interface StateAuth extends AuthInfo {
  loginByEmail: (user: ILoginIn) => Promise<boolean>;
  loginByGoogle: (user: IGoogleLogin) => Promise<boolean>;
  loginByFacebook: (user: IFacebookLogin) => Promise<boolean>;
  impersonateLogin: (token: string) => Promise<AuthInfo>;
  logoutUser: () => void;
  setConnectedCustomer: (user: IUser) => void;
}
export interface IUser {
  id: number;
  username: string;
  token: string;
  refreshToken?: string;
  roles: string[];
  iat?: number;
  exp?: number;
  last_login?: number;
  isSubscribed: boolean;
  firstName: string;
  lastName: string;
  groupCode: string | null;
}
export interface GroupCode {
  channel: string;
  code: string;
}
export type AuthActionType =
  | {
      type: typeof SET_AUTH_DETAILS;
      data: AuthInfo;
    }
  | {
      type: typeof REMOVE_AUTH_DETAILS;
    };

export interface AuthAction {
  type: AuthActionType;
  payload: AuthInfo;
}
export interface IResponseToken {
  token: string;
  customer: string;
  tokenValue: string | null;
  refreshToken?: string;
}
export type UserType = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  leadOrigin?: string;
  agreementCheck?: boolean;
};
export interface AxiosRequestConfigData {
  addBearerUser?: boolean;
  customToken?: string | boolean;
}
export interface ICreditTransfer {
  amount: number;
  transferDate: string;
  reason: string;
  comment: string;
}
export interface ISponsorshipCoupon {
  code: string;
}
export enum ESegmentCustomer {
  PROSPECT_0 = "prospect0",
  PROSPECT_6 = "prospect6",
  PROSPECT4_6 = "prospect46",
  PROSPECT_366 = "prospect366",
  PROSPECT_666 = "prospect666",
  PROSPECT_999 = "prospect999",
  ACTIVE_ENGAGED = "activeEngaged",
  ACTIVE_SLIMMING_ENGAGED = "activeSlimmingEngaged",
  ACTIVE_NO_ENGAGED = "activeNotEngaged",
  RETENTION = "retention",
  RETENTION_SLIMMING = "retentionSlimming",
  REACT_9 = "react9",
  REACT_91 = "react91",
  REACT_121 = "react121",
  REACT_366 = "react366",
  REACT_1YEAR = "react1year",
  REACT_2YEAR = "react2years",
  REACT_3YEAR = "react3years",
  REACT_XYEAR = "reactXyears",
  NONE = "NONE",
}

export enum EGroupCustomer {
  GENERAL = "KITCHEN_DIETBON_GENERAL",
  NONE = "NONE",
}
export interface IAllUpcomingOrder {
  allUpcomingOrders: IOrder[];
}
export interface ICustomer {
  id: number;
  defaultAddress: string;
  user: {
    verified: boolean;
    sponsorshipCoupon: ISponsorshipCoupon | null;
    sponsoredUsersCount: number | null;
    maxAllowedSponsorships: number | null;
    creditsBySponsorship: number | null;
    segment: ESegmentCustomer;
  };
  email: string;
  firstName: string;
  lastName: string;
  gender: string;
  fullName: string;
  allUpcomingOrders: IOrder[];
  currentSubscription?: {
    id: number;
    bundleType?: TProgramType;
    bundle: IVariant;
    state: ESubscriptionState;
    pastOrderCount: number;
    remainingOrderCount: number;
    hasAnAwaitingPaymentRegularisation: boolean;
    bundlePrice: number;
    subscriptionType?: TSubscriptionType;
    nextUpcomingOrderToPay?: IOrder;
    firstUpcomingOrder?: IOrder;
    upcomingOrders?: {
      [key: number]: IOrder;
    };
  } | null;
  totalCreditAmount: number;
  creditExpirationDate: string | null;
  creditTransfers: ICreditTransfer[];
  excludedFoodPreferences: string[];
  ordersCount: number;
  paidOrdersCount: number;
  engaged: boolean;
  firstOrderDate: string;
  mobilePhone: string | null;
  phoneNumber: string | null;
  optimumFormState: EOptimumStatus;
  birthday?: string;
  canStab?: boolean;
  canStabError?: string;
}
export type TSubscriptionType = {
  engagementPeriodNumber: number;
  possibleDeferral: number;
};

export enum ESubscriptionState {
  ON_GOING = "on_going",
  PAUSED = "paused",
  DONE = "done",
  CANCELED = "canceled",
  CLOSED = "closed",
  UNPAID = "unpaid",
}
export type TSubscriptionState = ValueOf<typeof ESubscriptionState>;
export type IEvaluation = {
  [key: string]: string;
  firstName: string;
  email: string;
  phoneNumber: string;
  familyStatus: string;
};
export type WindowWithDataLayer = Window & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dataLayer?: Record<string, any>[];
};
export interface IGoogleLogin {
  apiCode: string;
  tokenValue?: string;
}
export interface IFacebookLogin {
  accessToken: string;
  tokenValue?: string;
}

export enum EModalType {
  FIXED = "fixed",
  FLOTTANT = "flottant",
}
export interface IObject {
  [key: string]: string;
}

export enum ETooltipPosition {
  POSITION_TOP = "top",
  POSITION_BOTTOM = "bottom",
  POSITION_LEFT = "left",
  POSITION_RIGHT = "right",
}

export type TTooltipPosition = ValueOf<typeof ETooltipPosition>;
export type TDateObject = {
  year: number;
  month: number;
  day: number;
};

type ButtonTag = "a" | "button";

export type TButton = {
  label: string;
  href?: string;
  tag?: ButtonTag;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export enum ETitleLineStyles {
  STYLE_1 = "style1",
  STYLE_2 = "style2",
  STYLE_3 = "style3",
  STYLE_4 = "style4",
  STYLE_5 = "style5",
  STYLE_6 = "style6",
}

export type TTitleLineStyle = ValueOf<typeof ETitleLineStyles>;

export interface IUrlObject {
  pathname: string;
  query: IObject;
}

export interface IOptimumQuizApi {
  id: string;
  title: string;
  description: string;
  task_status: string;
  surveys: {
    index: string;
    title: string;
    sections: {
      index: string;
      questions: {
        id: string;
        type: string;
        index: string;
        value: string;
        required: string;
        help: string;
        help_title: string;
        description: string;
        answers: {
          label: string;
        }[];
      }[];
    }[];
  }[];
}

export interface IOptimumRecommendationsApiMeals {
  id: string;
  quantity: number;
  available: boolean;
}

export interface IOptimumRecommendationsApi {
  recommendation_status: string;
  recommendation: IOptimumRecommendationsApiMeals[];
  command_id: string;
  user_id: string;
  score: number;
}

export interface IOptimumRecommendedProductApi {
  id: string;
  score: number;
}
export interface IOptimumRecommendations {
  recommendationStatus: string;
  recommendation: { id: string; quantity: number; available: boolean }[];
  commandId: string | undefined;
  userId: string;
  score: number;
}

export interface IQuizResponseApi {
  question_id: string;
  answer: string;
}

export interface IQuizResponse {
  questionId: string;
  answer: string;
}

export interface IRegularizationAmount {
  regularizationAmount: number;
}

export enum EOptimumStatus {
  STARTED = "not_started",
  GOING = "on_going",
  COMPLETE = "completed",
}

export type TResultsBilanDataItem = {
  [key: string]: { question: string; answer: string | number };
};

export type TResultsRoas = {
  amount: number;
};

export type TResultsBilanData = {
  evaluation: string;
  email: string;
  firstName: string;
  phoneNumber: string;
  datetime_slot?: string;
  immediate_recall?: boolean;
};

export type TResponseBmi = {
  original_value: number;
  value: number;
  message: string;
};
export type TBmi = {
  originalValue: number;
  value: number;
  message: string;
};
export type TWeightGoal = {
  weightGoal: number;
  weightLossGoal: number;
  bundleVariant: {
    id: number;
    code: string;
  };
};
export type TResponseResultBilan = {
  qls: number;
  bmi: {
    height: number;
    current: TResponseBmi;
    desired: TResponseBmi;
    recommended: TResponseBmi;
    healthy: TResponseBmi;
  };
  weightGoal: {
    height: number;
    currentWeight: number;
    desired: TWeightGoal;
    bmiBasedRecommendation: TWeightGoal;
  };
  customerId: number;
};
export type TResultBilan = {
  qls: number;
  bmi: {
    height: number;
    current: TBmi;
    desired: TBmi;
    recommended: TBmi;
    healthy: TBmi;
  };
  weightGoal: {
    height: number;
    currentWeight: number;
    desired: TWeightGoal;
    bmiBasedRecommendation: TWeightGoal;
  };
  customerId: number;
};
export type TResponseObjectiveWeightBilan = {
  weightLossGoal: number;
  weightGoal: number;
  bundleRecommended: {
    id: number;
    code: string;
    duration: number;
  };
};
export type TObjectiveWeightBilan = {
  bundleDuration: number;
  weightGoal: number;
  isAchievable: boolean;
};

export enum EDevice {
  MOBILE = "mobile",
  DESKTOP = "desktop",
}
export enum EFrontType {
  STYLE1 = "perrap",
  STYLE2 = "promno",
  STYLE3 = "swinv",
  STYLE4 = "duree1",
  STYLE5 = "duree2",
  STYLE6 = "orig5jdef",
  STYLE7 = "duree1eco",
  STYLE8 = "duree2eco",
  STYLE9 = "parenok",
  STYLE10 = "duree3",
  STYLE11 = "newbilana",
  STYLE12 = "newbilanb",
  STYLE13 = "bg",
  STYLE14 = "duree3eco",
  STYLE15 = "duree3ecofrais1",
  STYLE16 = "duree3ecofrais2",
  STYLE17 = "duree3ecofrais1a",
  STYLE18 = "duree3ecofrais2a",
}
export enum EFrontType2 {
  STYLE1 = "bilf",
  STYLE2 = "bilob",
  STYLE3 = "bilantelbl",
  STYLE4 = "bilancren",
  STYLE5 = "rappelez",
}
