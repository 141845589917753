import { Content } from "@prismicio/client";
import React from "react";
import cn from "clsx";
import { SliderSlick, TitlePage } from "@components/commun";
import { Trans, useTranslation } from "next-i18next";
import { Text } from "@components/ui";
import { PrismicImage, PrismicRichText } from "@prismicio/react";
import { REVIEW_NORMAL_TYPE } from "@middleware/constants";
import style from "./HomeReviews.module.scss";
import { sliderSettings } from "./SliderSettings";

interface Props {
  reviews?: Content.AvisDocument[];
}
export default function HomeReviews({ reviews }: Props) {
  const { t } = useTranslation();
  const orderedReview = reviews?.sort(
    (a, b) =>
      parseInt(a.data.positionHomePage?.toString() ?? "100") -
      parseInt(b.data.positionHomePage?.toString() ?? "100"),
  );

  return (
    <div className={style.reviews}>
      <div className="lk-wrap text-center">
        <TitlePage styleClass="caveat" className={style.title}>
          {t("pages.everyoneHasProgram")}
        </TitlePage>
        <Text className={style.text}>
          <Trans
            t={t}
            i18nKey={"pages.dietbonCustomers"}
            components={{
              br: <br />,
            }}
          />
        </Text>
      </div>
      <div className={style.slider}>
        <SliderSlick settings={sliderSettings}>
          {orderedReview?.map((review) => {
            if (
              review.data.type !== REVIEW_NORMAL_TYPE ||
              !review.data.useInHomePage
            )
              return null;

            return (
              <div key={review.id} className={style.review}>
                <div className={style.content}>
                  <div>
                    <span
                      className={cn(
                        style.programType,
                        style[review.data.program],
                      )}
                    >
                      {t("pages.programType", { type: review.data.program })}
                    </span>
                    <Text className={style.name}>
                      <strong>{review.data.name} </strong>
                      {review.data.weightLost}
                    </Text>
                    <Text className={style.description}>
                      <PrismicRichText field={review.data.shortDescription} />
                    </Text>
                  </div>
                </div>
                <div className={style.thumb}>
                  <div>
                    <PrismicImage field={review.data.featureExcerpt} />
                  </div>
                </div>
              </div>
            );
          })}
        </SliderSlick>
      </div>
    </div>
  );
}
