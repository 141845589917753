import { UrlObject } from "url";
import { KeyTextField, RichTextField } from "@prismicio/client";
import { ValueOf } from "next/dist/shared/lib/constants";
import { ImageField } from "@prismicio/types";
import { TFunctionTrans } from "./i18nextType";

export interface BasicPageProps {
  translation: TFunctionTrans;
}
export interface ITab {
  code: string;
  title: string;
  link: string | UrlObject;
  active: boolean;
}
export type imageProps = {
  width: number;
};
export interface IBilanFields {
  firstName: string;
  email: string;
  mobilePhone?: string;
}
export type TRichTextField = {
  type: string;
  text?: string;
};
export type TScoreData = {
  label?: string;
  color?: string;
};
export type TDietitianData = {
  title?: RichTextField;
  text?: RichTextField;
};
export type TBilanData = {
  height: number;
  weightToLoss: number;
  firstWeightToLoss: number;
  currentWeight: number;
  desiredWeight: number;
  healtyWeight: number;
  recommendedWeight: number;
  currentImc: number;
  desiredImc: number;
  recommendedImc: number;
  healthyImc: number;
};
export type TCoordonates = {
  x: number;
  y: number;
};

export enum ERecommendationProccessType {
  ADD = "add",
  REMOVE = "remove",
}

export type TRecommendationProccessType = ValueOf<
  typeof ERecommendationProccessType
>;

export type TSpecialist = {
  image: ImageField;
  name: KeyTextField;
  speciality: KeyTextField;
};
export interface TKeyValueMap {
  [key: string]: string | number;
}
export enum ELandingPageType {
  CLASSIC = "classic",
  GUIDE = "guide",
}
export type TLandingPageType = ValueOf<typeof ELandingPageType>;
export type TFrontSessionResponse = {
  app: string;
  regionCode: string;
  region: string;
};
