import Image from "next/image";
import cn from "clsx";
import { Content } from "@prismicio/client";
import {
  PrismicImage,
  PrismicRichText,
  SliceComponentProps,
} from "@prismicio/react";
import { Text } from "@components/ui";
import trustPilotLogo from "@static/images/icons/trustpilot-logo.png";
import TrustPilotRating from "@components/ui/TrustPilotRating/TrustPilotRating";
import AwardIcon from "@static/images/icons/award.png";
import { useRouter } from "next/router";
import { URL_HOME } from "@middleware/constants";
import { useTranslation } from "next-i18next";
import style from "./Trustpilot.module.scss";

export type TrustpilotProps = SliceComponentProps<Content.TrustpilotSlice>;

const Trustpilot = ({ slice }: TrustpilotProps): JSX.Element => {
  const { t } = useTranslation();
  const { ratingText, verifiedReviews, trustpilotRatingValue, award } =
    slice.primary;
  const ratingValueString = parseFloat(
    trustpilotRatingValue?.toString() ?? "0",
  );
  const router = useRouter();
  const isHomePage = router.pathname === URL_HOME;
  const { TFILogo: tfi, M6logo: m6 } = slice.primary;
  const showTVLogos = typeof m6.url === "string" || typeof tfi.url === "string";
  const showTfiLogo = typeof tfi.url === "string";
  const showM6Logo = typeof m6.url === "string";
  const hasProductOfTheYearLogo =
    typeof slice.primary.productOfTheYearLogo.url === "string" && !isHomePage;

  return (
    <section
      data-slice-type={slice.slice_type}
      data-slice-variation={slice.variation}
      className={cn(style.section, {
        [style.hasProductOfTheYearLogo]: hasProductOfTheYearLogo,
      })}
    >
      {hasProductOfTheYearLogo && (
        <div className={style.productOfTheYearLogo}>
          <PrismicImage
            field={slice.primary.productOfTheYearLogo}
            width={104}
            height={145}
          />
        </div>
      )}
      <div className={style.wrapper}>
        <div
          className={cn("lk-wrap", {
            large: showTVLogos,
          })}
        >
          <div className={cn(style.flex, { [style.isHomePage]: isHomePage })}>
            <div className={style.numberOne}>
              <Image src={AwardIcon} alt="" width={25} />
              <Text>
                <PrismicRichText field={award} />
              </Text>
            </div>
            <div className={style.trustpilotStars}>
              <div className={style.trustpilotwrap}>
                <Text variant="p" className={style.text}>
                  {ratingText}
                </Text>
                <div className={style.ratingStars}>
                  <TrustPilotRating value={ratingValueString} />
                  <Text variant="p" className={style.avisCount}>
                    {verifiedReviews}
                  </Text>
                </div>
                <div className={style.tpLogo}>
                  <Image src={trustPilotLogo} width={105} alt="" />
                </div>
              </div>
            </div>
            {showTVLogos && (
              <div className={style.seenOn}>
                <span>{t("pages.seenOn")}</span>

                {showTfiLogo && (
                  <span
                    className={style.tfi}
                    style={{ backgroundImage: `url(${tfi.url})` }}
                  ></span>
                )}

                {showM6Logo && showTfiLogo && t("pages.and")}

                {showM6Logo && (
                  <span
                    className={style.m6}
                    style={{ backgroundImage: `url(${m6.url})` }}
                  ></span>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Trustpilot;
