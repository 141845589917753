import { Container } from "@components/ui";
import { useTranslation } from "next-i18next";
import { MainLayout } from "@layouts/MainLayout/MainLayout";
import { BAR_PRISMIC_TYPE } from "@middleware/constants";
import { Head } from "@components/commun";
import { Content } from "@prismicio/client";
import { SliceZone } from "@prismicio/react";
import { usePromotionFooter } from "@middleware/hooks";
import HomeReviews from "@components/pages/slider/HomeReviews/HomeReviews";
import HomeProduct from "@components/pages/slider/HomeProduct/HomeProduct";
import { GetStaticProps, GetStaticPropsContext } from "next";
import { createClient } from "prismicio";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { components } from "../../../src/slices";

type PageProps = {
  page: Content.PageAccueilDocument;
  promotions: Content.HomepageDocument;
  slider: Content.BlockSliderDocument;
  reviews: Content.AvisDocument[];
};

export const getStaticProps: GetStaticProps = async ({
  locale,
  previewData,
}: GetStaticPropsContext) => {
  const client = createClient({ previewData });
  const page = await client.getSingle("page_accueil");
  const promotions = await client.getSingle("homepage");
  const slider = await client.getSingle("block_slider");
  const reviews = await client.getAllByType("avis");

  return {
    props: {
      ...(await serverSideTranslations(locale as string, [
        "common",
        "validation",
        "header",
        "footer",
      ])),
      page,
      slider,
      reviews,
      promotions,
    },
  };
};

const Home = ({ page, slider, reviews, promotions }: PageProps) => {
  const { t } = useTranslation();
  const { promotionFooterSlices } = usePromotionFooter(promotions);

  return (
    <Container className="page-cms homepage">
      <SliceZone slices={promotionFooterSlices} components={components} />
      <Head
        title={t("seo.titleHomePage")}
        description={t("seo.descriptionHomePage")}
      />
      <SliceZone slices={page.data.slices} components={components} />

      <HomeProduct
        page={slider}
        bar={
          promotions.data.slices.find(
            (slice) => slice.slice_type === BAR_PRISMIC_TYPE,
          ) as Content.BarreProgrammeSlice
        }
      />
      <HomeReviews reviews={reviews} />
    </Container>
  );
};

Home.layout = MainLayout;
export default Home;
