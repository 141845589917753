import { Button, Modal, Text } from "@components/ui";
import { getIsProspect } from "@middleware/helpers";
import { useCustomer, useWindow } from "@middleware/hooks";
import React, { useState } from "react";
import "react-phone-input-2/lib/style.css";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import Picto from "@static/images/icons/phone-menu-violite.svg";
import style from "./ModalCallMe.module.scss";
import CallMeForm from "./CallMeForm/CallMeForm";

export default function ModalCallMe() {
  const { customer } = useCustomer();
  const { isDesktop } = useWindow();
  const [isOpen, setIsOpen] = useState(false);
  const isProspect = getIsProspect(customer);
  const { t } = useTranslation();

  const handleShowingModal = (open: boolean) => {
    setIsOpen(open);
  };

  return isProspect && isDesktop ? (
    <div className={style.modal}>
      <Button className={style.button} onClick={() => handleShowingModal(true)}>
        <Image src={Picto} alt="" />
        <Text>{t("buttons.callMe")}</Text>
      </Button>
      {isOpen && (
        <div className={style.modalWrap}>
          <Modal
            onClose={() => handleShowingModal(false)}
            className={style.filterModal}
          >
            <CallMeForm />
          </Modal>
        </div>
      )}
    </div>
  ) : null;
}
