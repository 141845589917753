import Api from "@middleware/api";
import {
  CART_TOKEN_STORAGE,
  CLIENT_IP,
  FRONT_SESSION_CODE,
} from "@middleware/constants";
import { TFrontSessionResponse } from "@middleware/types";

export const getFromStorage = (key: string): unknown => {
  try {
    if (typeof window !== "undefined") {
      const value = window.localStorage.getItem(key) as string;

      return JSON.parse(value) as string;
    }
  } catch (error) {
    return null;
  }

  return null;
};

export const setToStorage = (
  key: string,
  value: string | object | null | number,
) => {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    return false;
  }

  return true;
};

export const saveSessionParams = (datas: {
  [key: string]: string | object | null;
}): void => {
  Object.keys(datas).map((key: string) => {
    if (datas[key] !== null) setToStorage(key, datas[key]);

    return 1;
  });

  return;
};

export const clearCartTokenFromStorage = () => {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(CART_TOKEN_STORAGE);
    }
  } catch (error) {
    return false;
  }

  return true;
};

export const clearToStorage = () => {
  try {
    if (typeof window !== "undefined") {
      window.localStorage.clear();
    }
  } catch (error) {
    return false;
  }

  return true;
};

export const getUtmSessions = () => {
  return {
    utm_source: getFromStorage("utm_source"),
    utm_medium: getFromStorage("utm_medium"),
    utm_campaign: getFromStorage("utm_campaign"),
    ab: getFromStorage("ab"),
    vr: getFromStorage("vr"),
  };
};

export const getFrontSessionFromApi = async () => {
  const ip = getFromStorage(CLIENT_IP) as string;
  const response = await Api.customer.getFrontSessionInfo(ip);
  if (response.status === 200) {
    const data = response.data as TFrontSessionResponse;
    setToStorage(FRONT_SESSION_CODE, data.app);
  }
};
