import Image from "next/image";
import { useTranslation } from "next-i18next";
import { useCustomer } from "@middleware/hooks";
import { Link, Text } from "@components/ui";
import {
  URL_PAGE_MEN,
  URL_PAGE_STABILISATION,
  URL_PAGE_WOMEN,
} from "@middleware/constants";
import PictoWomen from "@static/images/header/picto-women.svg";
import PictoWomenHover from "@static/images/header/picto-women-hover.svg";
import PictoMen from "@static/images/header/picto-men.svg";
import PictoMenHover from "@static/images/header/picto-men-hover.svg";
import PictoStab from "@static/images/header/picto-stab.svg";
import PictoStabHover from "@static/images/header/picto-stab-hover.svg";
import style from "./SubtabMenu.module.scss";

export const SubtabMenu = () => {
  const { t } = useTranslation("header");
  const { customer } = useCustomer();
  const showStabMenuItem = customer?.canStab ?? false;

  return (
    <div className={style.subtabMenu}>
      <div>
        <div className="lk-wrap">
          <ul>
            <li>
              <Link href={URL_PAGE_WOMEN} className="simple">
                <Image src={PictoWomen} alt="" />
                <Image src={PictoWomenHover} alt="" className={style.hover} />
                <Text variant="span">{t("menu.pProgramWomen")}</Text>
              </Link>
            </li>
            <li>
              <Link href={URL_PAGE_MEN} className="simple">
                <Image src={PictoMen} alt="" />
                <Image src={PictoMenHover} alt="" className={style.hover} />
                <Text variant="span">{t("menu.pProgramMen")}</Text>
              </Link>
            </li>
            {showStabMenuItem && (
              <li>
                <Link href={URL_PAGE_STABILISATION} className="simple">
                  <Image src={PictoStab} alt="" />
                  <Image src={PictoStabHover} alt="" className={style.hover} />
                  <Text variant="span">{t("menu.labelStab")}</Text>
                </Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};
